import React, { useState } from 'react'
import './Header.scss'
import { image } from '../../../app/utils/common'

const Header = () => {
  const [activeSection, setActiveSection] = useState('home')

  const handleClickOfDownloadApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.aaradhana&pli=1'
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/in/app/aradhanaa/id6717586191'
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.aaradhana&pli=1'
    }
  }

  const scrollToSection = (id) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
      setActiveSection(id)
    }
  }

  return (
    <div className="header-container">
      <div className="header">
        <div className="aaradhana-logo">
          <img src={image.logo} alt="Aaradhana Logo" />
        </div>
        <div className="aaradhana-navbar">
          <ul>
            <li onClick={() => scrollToSection('home')} className={activeSection === 'home' ? 'active' : ''}>
              <a href="#home">Home</a>
            </li>
            <li onClick={() => scrollToSection('donations')} className={activeSection === 'donations' ? 'active' : ''}>
              <a href="#donations">Donations</a>
            </li>
            <li onClick={() => scrollToSection('poojas')} className={activeSection === 'poojas' ? 'active' : ''}>
              <a href="#poojas">Poojas</a>
            </li>
            <li onClick={() => scrollToSection('testimonials')} className={activeSection === 'testimonials' ? 'active' : ''}>
              <a href="#testimonials">Testimonials</a>
            </li>
          </ul>
          <button onClick={handleClickOfDownloadApp}>Download App</button>
        </div>
      </div>
    </div>
  )
}

export default Header
