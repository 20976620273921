import React from 'react'
import './Footer.scss'
import { FaRegCopyright } from 'react-icons/fa'
import { image } from '../../../app/utils/common'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-info">
        <div className="footer-one">
          <div className="logo-image">
            <img src={image.logo} alt="Logo" />
          </div>
          <a href="#">Your Digital Gateway to Connect with Temples and Trusts.</a>
          <div className="footer-socialmedia-logos">
            {['facebook', 'twitter', 'instagram', 'linkedin'].map((platform) => (
              <div className="social-logo" key={platform}>
                <img src={image[platform]} alt={platform} />
              </div>
            ))}
          </div>
        </div>

        <div className="footer-column">
          <h2>Useful Links</h2>
          <a href="#home">Home</a>
          <a href="#donations">Donations</a>
          <a href="#poojas">Poojas</a>
          <a href="#testimonials">Testimonials</a>
        </div>

        <div className="footer-column">
          <h2>Our Services</h2>
          <a href="#"> Pooja Booking</a>
          <a href="#">Community Donations</a>
          <a href="#"> Spiritual Events</a>
          <a href="#">Temple Updates</a>
        </div>

        <div className="footer-column contact-info">
          <h2>Contact Info</h2>
          <div className="contact">
            <img src={image.call} alt="Call Icon" />
            <a href="tel:+919099588349">(+91) - 90995 - 88349</a>
          </div>
          <div className="contact">
            <img src={image.message} alt="Message Icon" />
            <a href="mailto:higcorona@gmail.com">higcorona@gmail.com</a>
          </div>
          <div className="contact">
            <img src={image.location} alt="Location Icon" />
            <a href="#">395-010, Ventureboo LLP, Surat, Gujarat</a>
          </div>
        </div>
        <div className="return-to-home">
          <a href="#home">
            <img src={image.returnHomeRocket} alt="Return Home" />
          </a>
        </div>
      </div>

      <div className="final-footer">
        <img src={image.allrightslogo} alt="all rights reserved" />
        2024 ARADHANAA APP | All rights reserved
      </div>
    </footer>
  )
}

export default Footer
