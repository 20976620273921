import React, { forwardRef, useEffect, useState } from 'react'
import './TestimonialTemplate.scss'
import CircleLottie from '../../../assets/Lottie/blackcircle.json'
import { image } from '../../../app/utils/common'
import Lottie from 'lottie-react'

const images = [image.testmonialone, image.testmonialfour, image.testmonialtwo, image.testmonialthree]

const TestimonialTemplate = forwardRef((props, ref) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div id="testimonials" ref={ref} className="testimonial">
      <div className="testimonial__title">TESTIMONIAL</div>
      <div className="testimonial__subtitle">
        <span>What our users say</span> <span>about us?</span>
      </div>

      <div className="testimonial_animation-information">
        <div className="testimonial__animation">
          <Lottie animationData={CircleLottie} loop={true} />
        </div>
        <div className="testimonial__content">
          <div className="testimonial__quote">The best Spiritual Community Funding app ever!</div>
          <div className="testimonial__description">
            As a user, I can confidently say this is the best spiritual community funding app ever! It has made it so easy to connect with others and
            support meaningful initiatives within our faith community.
          </div>
          <div className="testimonial__images">
            {/* {currentImages.map((src, index) => (
              <img key={index} src={src} alt={`Testimonial ${index + 1}`} className="testimonial__image" />
            ))} */}
            <img src={image.testmonialfour} className="testimonial__image" />
            <img src={image.testmonialone} className="testimonial__image" />
            <img src={image.testmonialthree} className="testimonial__image" />
            <img src={image.testmonialtwo} className="testimonial__image" />
          </div>
          <div className="testimonial__author">Prakash Patil</div>
        </div>
      </div>
      <img src={image.DarkStar} alt="" className="darkstar-img-tt" />
    </div>
  )
})

export default TestimonialTemplate
