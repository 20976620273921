import React from 'react'
import './EventManagementTemp.scss'
import CircleLottie from '../../../assets/Lottie/blackcircle.json'
import { image } from '../../../app/utils/common'
import Lottie from 'lottie-react'

export const EventManagementTemp = () => {
  return (
    <div className="event-container">
      <div className="event-subcontainer">
        <div className="event-information">
          <div className="event-advantages">ADVANTAGES</div>
          <div className="event-mngmt">Event Management</div>
          <div className="noti-icon-heading">
            <div className="notification-circle">
              <img src={image.notificationevent} alt="Notification Icon" />
            </div>
            <div className="event-noti-heading">Clever Notifications</div>
          </div>
          <div className="event-description">
            Event Management & Clever Notifications "Effortlessly manage and organize religious events with our user-friendly platform, from
            scheduling to participant coordination. Stay updated with timely notifications tailored to your spiritual interests, ensuring you never
            miss an important event or reminder while enjoying a seamless experience."
          </div>
        </div>

        <div className="event-image-lottie">
          <div className="event-lottie">
            <Lottie animationData={CircleLottie} loop={true} />
          </div>
          <div className="event-mobile-image">
            <img src={image.eventiphone} alt="Mobile Device" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventManagementTemp
