import React from 'react'
import './InstagramFeedsTemp.scss'
import CircleLottie from '../../../assets/Lottie/blackcircle.json'
import { image } from '../../../app/utils/common'
import Lottie from 'lottie-react'

const InstagramFeedsTemp = () => {
  return (
    <div className="instagram-feeds-container">
      <div className="instagram-feeds-subcontainer">
        <div className="instagram-animation-wrapper">
          <div className="animation-circle">
            <Lottie animationData={CircleLottie} loop={true} />
          </div>
          <div className="instagram-imageone-wrapper">
            <img src={image.iphoneinsta} alt="" className="instagram-feed-image" />
          </div>
          <div className="instagram-imagetwo-wrapper">
            <img src={image.iphoneinstatwo} alt="" className="instagram-feed-image" />
          </div>
        </div>

        <div className="instagram-info-section">
          <div className="instagram-title">INSTAGRAM FEEDS LIKE</div>
          <div className="instagram-description-wrapper">
            <div className="instagram-icon-wrapper">
              <img src={image.instastar} alt="" className="instagram-icon" />
            </div>
            <div className="instagram-subtitle">Photos, Videos and Audio Management</div>
          </div>
          <div className="instagram-details">
            Photos, Videos, and Audio Management "Curate and share your spiritual journey with ease using our integrated media management tools.
            Upload and organize photos, videos, and audio clips to create engaging feeds that inspire and connect with your community.
          </div>
        </div>
      </div>
      <div className="aaradhana-namaste-image">
        <img src={image.communitydonationlogo} alt="" className="instagram-footer-image" />
      </div>
    </div>
  )
}

export default InstagramFeedsTemp
