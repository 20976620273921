import React, { forwardRef } from 'react'
import './DonateToHelpTemp.scss'
import ProgressBar from '@ramonak/react-progress-bar'
import { image } from '../../../app/utils/common'

const DonateToHelpTemp = forwardRef((props, ref) => {
  return (
    <div id="donations" ref={ref} className="donatehelp-container">
      <div className="donatehelp-content">
        <div className="donatehelp-title">DONATE TO HELP</div>
        <div className="donatehelp-subtitle">Make Donation to Help Community</div>
        <div className=" donatehelp-cardcontainer">
          <div className="donatehelp-card">
            <div className="donatehelp-image">
              <img src={image.donationone} alt="Donation Banner" />
            </div>
            <div className="donatehelp-card-info">
              <div className="donatehelp-heading">Become those who do</div>
              <div className="donatehelp-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
              </div>
              <div className="donatehelp-stats">
                <div className="donatehelp-raised">Raised: Rs.50,540</div>
                <div className="donatehelp-goal">Goal: Rs.85,000</div>
              </div>
              <div className="donatehelp-progress">
                <ProgressBar bgColor="linear-gradient(to right, #ff9406, #ff5e4d)" height="15px" labelSize="10px" completed={60} />
              </div>
              <div className="donatehelp-button-container">
                <button className="donatehelp-button">Donate Now</button>
              </div>
            </div>
          </div>
          <div className="donatehelp-card">
            <div className="donatehelp-image">
              <img src={image.donationtwo} alt="Donation Banner" />
            </div>
            <div className="donatehelp-card-info">
              <div className="donatehelp-heading">Become those who do</div>
              <div className="donatehelp-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
              </div>
              <div className="donatehelp-stats">
                <div className="donatehelp-raised">Raised: Rs.50,540</div>
                <div className="donatehelp-goal">Goal: Rs.85,000</div>
              </div>
              <div className="donatehelp-progress">
                <ProgressBar bgColor="linear-gradient(to right, #ff9406, #ff5e4d)" height="15px" labelSize="10px" completed={60} />
              </div>
              <div className="donatehelp-button-container">
                <button className="donatehelp-button">Donate Now</button>
              </div>
            </div>
          </div>
          <div className="donatehelp-card">
            <div className="donatehelp-image">
              <img src={image.donationthree} alt="Donation Banner" />
            </div>
            <div className="donatehelp-card-info">
              <div className="donatehelp-heading">Become those who do</div>
              <div className="donatehelp-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
              </div>
              <div className="donatehelp-stats">
                <div className="donatehelp-raised">Raised: Rs.50,540</div>
                <div className="donatehelp-goal">Goal: Rs.85,000</div>
              </div>
              <div className="donatehelp-progress">
                <ProgressBar bgColor="linear-gradient(to right, #ff9406, #ff5e4d)" height="15px" labelSize="10px" completed={60} />
              </div>
              <div className="donatehelp-button-container">
                <button className="donatehelp-button">Donate Now</button>
              </div>
            </div>
          </div>
          <div className="donatehelp-card">
            <div className="donatehelp-image">
              <img src={image.donationthree} alt="Donation Banner" />
            </div>
            <div className="donatehelp-card-info">
              <div className="donatehelp-heading">Become those who do</div>
              <div className="donatehelp-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
              </div>
              <div className="donatehelp-stats">
                <div className="donatehelp-raised">Raised: Rs.50,540</div>
                <div className="donatehelp-goal">Goal: Rs.85,000</div>
              </div>
              <div className="donatehelp-progress">
                <ProgressBar bgColor="linear-gradient(to right, #ff9406, #ff5e4d)" height="15px" labelSize="10px" completed={60} />
              </div>
              <div className="donatehelp-button-container">
                <button className="donatehelp-button">Donate Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default DonateToHelpTemp
