import React from 'react'
import './CommunityDonationTemp.scss'
import CircleLottie from '../../../assets/Lottie/blackcircle.json'
import { image } from '../../../app/utils/common'
import Lottie from 'lottie-react'

const CommunityDonationTemp = () => {
  return (
    <div className="CommunityDonation-container">
      <div className="CommunityDonation-subcontainer">
        <div className="lottie-img">
          <div className="lotti-circle">
            <Lottie size={80} style={{ color: 'black' }} animationData={CircleLottie} loop={true} />
            <div className="mobile-image">
              <img src={image.communityDonationMobile} alt="" />
            </div>
          </div>
        </div>
        <div className="donationlogo-content">
          <img className="comm-donation-logo" src={image.communitydonationlogo} alt="" />
          <div className="features-communitydonation">
            <div className="feature-title">FEATURES</div>
            <div className="com-donation">Community Donation</div>
            <div className="starlogo-title-description">
              <div className="starlogo-title">
                <img src={image.starImage} alt="" />
                <div>Community Donation</div>
              </div>
              <div className="starlogo-description">
                Support our community initiatives with ease. Your contributions help strengthen our shared values and uplift those in need.
              </div>
            </div>
            <div className="starlogo-title-description">
              <div className="starlogo-title">
                <img src={image.hexagon} alt="" />
                <div>Pooja Booking</div>
              </div>
              <div className="starlogo-description">
                Book poojas and religious ceremonies effortlessly through the app. Choose from various options to simplify your spiritual rituals.
              </div>
            </div>
            <div className="starlogo-title-description">
              <div className="starlogo-title">
                <img src={image.cube} alt="" />
                <div>Spiritual Connection</div>
              </div>
              <div className="starlogo-description">
                Aradhanaa is your gateway to a deeper spiritual journey. Stay connected with the vibrant Hindu community and integrate faith into your
                daily life.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityDonationTemp
