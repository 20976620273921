import React from 'react'
import './FeaturesTemplate.scss'
import { image } from '../../../app/utils/common'
import Lottie from 'lottie-react'
import FeatureCircle from '../../../assets/Lottie/featurecircle.json'

export const FeaturesTemplate = () => {
  return (
    <div className="features-container">
      <div className="digitalplatform-marque">
        <marquee direction="right">
          <span style={{ marginRight: '80px' }}>Temple Events</span>
          <span style={{ marginRight: '80px' }}>Faith-Based</span>
          <span style={{ marginRight: '80px' }}>Initiatives</span>
          <span style={{ marginRight: '80px' }}>Virtual Darshan</span>
          <span style={{ marginRight: '80px' }}>Spiritual Education</span>
          <span style={{ marginRight: '80px' }}> Hindu Rituals</span>
          <span style={{ marginRight: '80px' }}> Temple Trust</span>
        </marquee>
      </div>
      <div className="songsvideos-marque">
        <marquee>
          <span style={{ marginRight: '80px' }}>Digital Platform</span>
          <span style={{ marginRight: '80px' }}>Spiritual Connections</span>
          <span style={{ marginRight: '80px' }}> Temple Services</span>
          <span style={{ marginRight: '80px' }}>Spiritual Community</span>
          <span style={{ marginRight: '80px' }}>Pooja Booking</span>
          <span style={{ marginRight: '80px' }}>Community Donations</span>
          <span style={{ marginRight: '80px' }}> Religious Ceremonies</span>
        </marquee>
      </div>

      <div className="features-screenshot">
        <div className="feature-list">
          <ul>
            <li>Real-time Updates</li>
            <li>You can Book Pooja</li>
            <li>Photo, Video & Audios</li>
            <li>Community Events</li>
            <li>Personalized Alerts</li>
            <li className="consume-media">Consume Media from Temples</li>
          </ul>
        </div>
        <div className="iphone-rings">
          <Lottie className="FeatureCircle" animationData={FeatureCircle} size={100} loop={true} />
          <div className="iphoneone">
            <img src={image.iphone1} alt="iPhone 1" />
          </div>
          <div className="iphonetwo">
            <img src={image.iPhone2} alt="iPhone 2" />
          </div>
          <div className="iphonethree">
            <img src={image.iPhone3} alt="iPhone 3" />
          </div>
        </div>
        {/* <img src={image.star} alt="" /> */}
      </div>
      <div className="aaradhanafade-image">
        <img src={image.aaradhanafadefeatures} alt="" />
      </div>
    </div>
  )
}

export default FeaturesTemplate
