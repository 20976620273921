import React from 'react'
import { image } from '../../../app/utils/common'
import './homepage.scss'
import TempleTemplate from '../../molecules/TempleTemplate/TempleTemplate'
import NamasteTemplate from '../../molecules/NamasteTemplate/NamasteTemplate'
import FeaturesTemplate from '../../molecules/FeaturesTemplate/FeaturesTemplate'
import ExperienceTemplate from '../../molecules/ExperienceTemplate/ExperienceTemplate'
import CommunityDonationTemp from '../../molecules/CommunityDonationTemp/CommunityDonationTemp'
import BookPujaTemplate from '../../molecules/BookPujaTemplate/BookPujaTemplate'
import EventManagementTemp from '../../molecules/EventManagementTemp/EventManagementTemp'
import DonateToHelpTemp from '../../molecules/DonateToHelpTemp/DonateToHelpTemp'
import InstagramFeedsTemp from '../../molecules/InstagramFeedsTemp/InstagramFeedsTemp'
import TestimonialTemplate from '../../molecules/TestimonialTemplate/TestimonialTemplate'
import DownloadAppTemplate from '../../molecules/DownloadAppTemplate/DownloadAppTemplate'

const Homepage = () => {
  return (
    <div>
      <TempleTemplate />
      <NamasteTemplate />
      <FeaturesTemplate />
      <ExperienceTemplate />
      <CommunityDonationTemp />
      <BookPujaTemplate />
      <EventManagementTemp />
      <DonateToHelpTemp />
      <InstagramFeedsTemp />
      <TestimonialTemplate />
      <DownloadAppTemplate />
    </div>
  )
}

export default Homepage
