import React, { useEffect, useRef, useState } from 'react'
import './NamasteTemplate.scss'
import { image } from '../../../app/utils/common'

export const NamasteTemplate = () => {
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect() // Stop observing once the element is visible
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect() // Clean up the observer
    }
  }, [])

  return (
    <div ref={ref} className={`namaste-container ${isVisible ? 'fade-in' : ''}`}>
      <div className="fade-temple">
        <img src={image.fadetemple} alt="" />
      </div>
      <div className="namaste-title">
        <span>A Digital Platform Your</span>
        <span>Gateway to Spiritual Connection.</span>
        <span>Welcome to Aradhanaa</span>
      </div>
      <div className="namaste-info">
        <span>The ultimate app designed to bring you closer to your spiritual journey. With Aradhanaa,</span>
        <span>you can seamlessly integrate your faith into your daily life and stay connected with the vibrant</span>
        <span>Hindu community across India. Explore our features that cater to every aspect of your spiritual needs.</span>
      </div>
      <div className="malefemale-temple">
        <div className="male-female-image">
          <div className="male slide-in-left">
            <img src={image.namastemale} alt="" />
          </div>
          <div className="female slide-in-right">
            <img src={image.namastefemale} alt="" />
          </div>
        </div>
        <div className="hindu-temple slide-in-bottom">
          <img src={image.hindutemple} alt="" />
        </div>
      </div>
    </div>
  )
}

export default NamasteTemplate
