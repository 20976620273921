import React, { forwardRef } from 'react'
import './BookPujaTemplate.scss'
import { image } from '../../../app/utils/common'

export const BookPujaTemplate = forwardRef((props, ref) => {
  return (
    <div id="poojas" ref={ref} className="bookpuja-container">
      <div className="pujarti-container">
        <div className="puja-aarti">POOJA / AARTI</div>
        <div className="book-puja-aarti">
          <span>Book Poojas with </span>
          <span>Temples</span>
        </div>
        <div className="simplify-rituals">
          <span> Simplify your spiritual rituals by booking poojas and religious ceremonies through the app.</span>
          <span>Choose from a variety of pooja options and make your offerings with ease.</span>
        </div>
      </div>

      <div className="pooja-cards">
        <div className="pooja-card">
          <div className="image-info-card">
            <div className="card-inside-image">
              <img src={image.ganpatiyagya} alt="" />
            </div>
            <div className="card-inside-info">
              <span className="card-title">Mahabhairav Aapda Haran Yagya</span>
              <span className="card-subtitle">For end of all troubles and sorrows</span>
              <span className="card-location">Vikrant Bhairav Mandir,Ujjain</span>
            </div>
          </div>
          <span className="pooja-date">29 September 2024</span>
          <div className="book-puja-button">Book Pooja</div>
        </div>
        <div className="pooja-card">
          <div className="image-info-card">
            <div className="card-inside-image">
              <img src={image.mahabhairavyagya} alt="" />
            </div>
            <div className="card-inside-info">
              <span className="card-title">Mahabhairav Aapda Haran Yagya</span>
              <span className="card-subtitle">For end of all troubles and sorrows</span>
              <span className="card-location">Vikrant Bhairav Mandir,Ujjain</span>
            </div>
          </div>
          <span className="pooja-date">29 September 2024</span>
          <div className="book-puja-button">Book Pooja</div>
        </div>
        <div className="pooja-card">
          <div className="image-info-card">
            <div className="card-inside-image">
              <img src={image.krishnayagya} alt="" />
            </div>
            <div className="card-inside-info">
              <span className="card-title">Mahabhairav Aapda Haran Yagya</span>
              <span className="card-subtitle">For end of all troubles and sorrows</span>
              <span className="card-location">Vikrant Bhairav Mandir,Ujjain</span>
            </div>
          </div>
          <span className="pooja-date">29 September 2024</span>
          <div className="book-puja-button">Book Pooja</div>
        </div>
        <div className="pooja-card">
          <div className="image-info-card">
            <div className="card-inside-image">
              <img src={image.krishnayagya} alt="" />
            </div>
            <div className="card-inside-info">
              <span className="card-title">Mahabhairav Aapda Haran Yagya</span>
              <span className="card-subtitle">For end of all troubles and sorrows</span>
              <span className="card-location">Vikrant Bhairav Mandir,Ujjain</span>
            </div>
          </div>
          <span className="pooja-date">29 September 2024</span>
          <div className="book-puja-button">Book Pooja</div>
        </div>
        <div className="pooja-card">
          <div className="image-info-card">
            <div className="card-inside-image">
              <img src={image.krishnayagya} alt="" />
            </div>
            <div className="card-inside-info">
              <span className="card-title">Mahabhairav Aapda Haran Yagya</span>
              <span className="card-subtitle">For end of all troubles and sorrows</span>
              <span className="card-location">Vikrant Bhairav Mandir,Ujjain</span>
            </div>
          </div>
          <span className="pooja-date">29 September 2024</span>
          <div className="book-puja-button">Book Pooja</div>
        </div>
        <div className="pooja-card">
          <div className="image-info-card">
            <div className="card-inside-image">
              <img src={image.krishnayagya} alt="" />
            </div>
            <div className="card-inside-info">
              <span className="card-title">Mahabhairav Aapda Haran Yagya</span>
              <span className="card-subtitle">For end of all troubles and sorrows</span>
              <span className="card-location">Vikrant Bhairav Mandir,Ujjain</span>
            </div>
          </div>
          <span className="pooja-date">29 September 2024</span>
          <div className="book-puja-button">Book Pooja</div>
        </div>
      </div>
    </div>
  )
})

export default BookPujaTemplate
