import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from '../../components/organisms/homepage/homepage'
import Header from '../../components/molecules/Header/Header'
import Footer from '../../components/molecules/Footer/Footer'
import NamasteTemplate from '../../components/molecules/NamasteTemplate/NamasteTemplate'
import BookPujaTemplate from '../../components/molecules/BookPujaTemplate/BookPujaTemplate'

const AppRoutes = () => {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/* <Route path="/pooja" element={<BookPujaTemplate />} /> */}
      </Routes>
      <Footer />
    </>
  )
}

export default AppRoutes
