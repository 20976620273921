import React from 'react'
import './DownloadAppTemplate.scss'
import { image } from '../../../app/utils/common'

const DownloadAppTemplate = () => {
  return (
    <div className="download-app">
      <div className="download-app__container">
        <div className="download-app__header">
          <div className="download-app__title">Ready To Get Started?</div>
          <div className="download-app__subtitle">
            Join us on a transformative spiritual journey today! Sign up now to connect, contribute, and deepen your faith.
          </div>
          <div className="download-app__features">
            <div className="download-app__feature">
              Download App <img src={image.AppleIcon} alt="" />
            </div>
            <div className="download-app__feature">
              Download App <img src={image.playstoreIcon} alt="" />
            </div>
          </div>
          <img src={image.bottomcircle} alt="" className="bottom-circle" />
        </div>
        <div className="download-app__images">
          <img src={image.iphone1} alt="" className="download-app__imageone" />
          <img src={image.iPhone2} alt="" className="download-app__imagetwo" />
          <img src={image.uppercircle} alt="" className="upper-circle" />
        </div>
      </div>
      <img src={image.DarkStar} alt="" className="dark-star-one-dat" />
      <img src={image.DarkStar} alt="" className="dark-star-two-dat" />
      <img src={image.LiteStar} alt="" className="lite-star-two-dat" />
      <div className="DownloadAppTemplate-shadow"></div>
    </div>
  )
}

export default DownloadAppTemplate
